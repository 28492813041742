import { t } from 'i18next';
import { retrieveReplyToAction,
  updateReplyToAction,
  retrieveFollowUpAction,
  updateFollowUpAction,
  retrieveReminderEmailAction,
  updateReminderEmailAction,
  retrieveConfirmationEmailAction,
  updateConfirmationEmailAction,
  getCustomEmailDisclaimerAction,
  updateEmailDisclaimerAction,
  getCertificateInfoAction,
  updateCertificateInfoAction,
  deleteCertificateInfoAction,
  getCertificateUrlAction } from './actionTypes';
import { updateWebinarDetailsAction } from '../Webinar/actions';

const initialState = {
  webinarKey: null,
  replyTo: {
    isFetching: false,
    obj: {},
    error: null
  },
  confirmationEmail: {
    isFetching: false,
    obj: {},
    error: null
  },
  reminderEmail: {
    isFetching: false,
    obj: {},
    error: null
  },
  followUpEmail: {
    absentee: {
      isFetching: false,
      obj: {},
      error: null
    },
    attendee: {
      isFetching: false,
      obj: {},
      error: null
    }
  },
  customEmailDisclaimer: {
    emailDisclaimerEnabled: false,
    emailDisclaimerText: ''
  },
  certificateInfo: {
    obj: {},
    isFetching: false,
    error: null,
    fetchingCertificateUrl: false
  },
  maliciousError: false
};

function updateEmailSubjects(state, details) {
  const stateCopy = { ...state };
  if (!details || !details.subject) {
    return stateCopy;
  }
  stateCopy.followUpEmail.absentee.obj.subject = `We missed you at ${details.subject}`;
  stateCopy.followUpEmail.attendee.obj.subject = `Thank you for attending ${details.subject}`;
  stateCopy.reminderEmail.obj.subject = `Reminder: ${details.subject} starts in [time]`;
  stateCopy.confirmationEmail.obj.subject = `${details.subject} Confirmation`;
  return stateCopy;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case retrieveReplyToAction.toString(): {
      const replyTo = { ...state.replyTo };
      replyTo.isFetching = true;
      const webinarKey = action.webinarKey;
      return {
        ...state,
        webinarKey,
        replyTo
      };
    }
    case retrieveReplyToAction.complete.toString(): {
      const replyTo = { ...state.replyTo };
      replyTo.isFetching = false;
      replyTo.obj = action.data;
      return {
        ...state,
        replyTo
      };
    }
    case retrieveReplyToAction.failed.toString(): {
      const replyTo = { ...state.replyTo };
      replyTo.isFetching = false;
      replyTo.error = action.error;
      return {
        ...state,
        replyTo
      };
    }
    case updateReplyToAction.toString(): {
      const replyTo = { ...state.replyTo };
      replyTo.isFetching = true;
      const webinarKey = action.webinarKey;
      return {
        ...state,
        webinarKey,
        replyTo
      };
    }
    case updateReplyToAction.complete.toString(): {
      const replyTo = { ...state.replyTo };
      replyTo.isFetching = false;
      return {
        ...state,
        replyTo
      };
    }
    case updateReplyToAction.failed.toString(): {
      const replyTo = { ...state.replyTo };
      replyTo.isFetching = false;
      replyTo.error = action.error;
      return {
        ...state,
        replyTo
      };
    }
    case retrieveFollowUpAction.toString(): {
      const followUpEmail = { ...state.followUpEmail };
      const attendeeTypeFollowUpEmail = followUpEmail[action.attendeeType];
      attendeeTypeFollowUpEmail.isFetching = true;
      const webinarKey = action.webinarKey;
      return {
        ...state,
        webinarKey,
        followUpEmail
      };
    }
    case retrieveFollowUpAction.complete.toString(): {
      const followUpEmail = { ...state.followUpEmail };
      const attendeeTypeFollowUpEmail = followUpEmail[action.attendeeType];
      attendeeTypeFollowUpEmail.isFetching = false;
      attendeeTypeFollowUpEmail.obj = action.data;
      return {
        ...state,
        followUpEmail
      };
    }
    case retrieveFollowUpAction.failed.toString(): {
      const followUpEmail = { ...state.followUpEmail };
      const attendeeTypeFollowUpEmail = followUpEmail[action.attendeeType];
      attendeeTypeFollowUpEmail.isFetching = false;
      attendeeTypeFollowUpEmail.error = action.error;
      return {
        ...state,
        followUpEmail
      };
    }
    case updateFollowUpAction.toString(): {
      const followUpEmail = { ...state.followUpEmail };
      const attendeeTypeFollowUpEmail = followUpEmail[action.attendeeType];
      attendeeTypeFollowUpEmail.isFetching = true;
      const webinarKey = action.webinarKey;
      return {
        ...state,
        webinarKey,
        followUpEmail,
        maliciousError: false
      };
    }
    case updateFollowUpAction.complete.toString(): {
      const followUpEmail = { ...state.followUpEmail };
      const attendeeTypeFollowUpEmail = followUpEmail[action.attendeeType];
      attendeeTypeFollowUpEmail.obj = action.followUpEmailObj;
      attendeeTypeFollowUpEmail.isFetching = false;
      return {
        ...state,
        followUpEmail
      };
    }
    case updateFollowUpAction.failed.toString(): {
      const followUpEmail = { ...state.followUpEmail };
      const attendeeTypeFollowUpEmail = followUpEmail[action.attendeeType];
      attendeeTypeFollowUpEmail.isFetching = false;
      attendeeTypeFollowUpEmail.error = action.error;
      const maliciousError = action.maliciousError;
      return {
        ...state,
        followUpEmail,
        maliciousError
      };
    }
    case retrieveReminderEmailAction.toString(): {
      const reminderEmail = { ...state.reminderEmail };
      reminderEmail.isFetching = true;
      const webinarKey = action.webinarKey;
      return {
        ...state,
        webinarKey,
        reminderEmail
      };
    }
    case retrieveReminderEmailAction.complete.toString(): {
      const reminderEmail = { ...state.reminderEmail };
      reminderEmail.isFetching = false;
      reminderEmail.obj = action.data;
      return {
        ...state,
        reminderEmail
      };
    }
    case retrieveReminderEmailAction.failed.toString(): {
      const reminderEmail = { ...state.reminderEmail };
      reminderEmail.isFetching = false;
      reminderEmail.error = action.error;
      return {
        ...state,
        reminderEmail
      };
    }
    case updateReminderEmailAction.toString(): {
      const reminderEmail = { ...state.reminderEmail };
      reminderEmail.isFetching = true;
      const webinarKey = action.webinarKey;
      return {
        ...state,
        webinarKey,
        reminderEmail,
        maliciousError: false
      };
    }
    case updateReminderEmailAction.complete.toString(): {
      const reminderEmail = { ...state.reminderEmail };
      reminderEmail.obj = action.data;
      reminderEmail.isFetching = false;
      return {
        ...state,
        reminderEmail
      };
    }
    case updateReminderEmailAction.failed.toString(): {
      const reminderEmail = { ...state.reminderEmail };
      reminderEmail.isFetching = false;
      reminderEmail.error = action.error;
      const maliciousError = action.maliciousError;
      return {
        ...state,
        reminderEmail,
        maliciousError
      };
    }
    case retrieveConfirmationEmailAction.toString(): {
      const confirmationEmail = { ...state.confirmationEmail };
      confirmationEmail.isFetching = true;
      const webinarKey = action.webinarKey;
      return {
        ...state,
        webinarKey,
        confirmationEmail
      };
    }
    case retrieveConfirmationEmailAction.complete.toString(): {
      const confirmationEmail = { ...state.confirmationEmail };
      confirmationEmail.isFetching = false;
      confirmationEmail.obj = action.data;
      return {
        ...state,
        confirmationEmail
      };
    }
    case retrieveConfirmationEmailAction.failed.toString(): {
      const confirmationEmail = { ...state.confirmationEmail };
      confirmationEmail.isFetching = false;
      confirmationEmail.error = action.error;
      return {
        ...state,
        confirmationEmail
      };
    }
    case updateConfirmationEmailAction.toString(): {
      const confirmationEmail = { ...state.confirmationEmail };
      confirmationEmail.isFetching = true;
      const webinarKey = action.webinarKey;
      return {
        ...state,
        webinarKey,
        confirmationEmail,
        maliciousError: false
      };
    }
    case updateConfirmationEmailAction.complete.toString(): {
      const confirmationEmail = { ...state.confirmationEmail };
      confirmationEmail.obj = action.data;
      confirmationEmail.isFetching = false;
      return {
        ...state,
        confirmationEmail
      };
    }
    case updateConfirmationEmailAction.failed.toString(): {
      const confirmationEmail = { ...state.confirmationEmail };
      confirmationEmail.isFetching = false;
      confirmationEmail.error = action.error;
      const maliciousError = action.maliciousError;
      return {
        ...state,
        confirmationEmail,
        maliciousError
      };
    }
    case updateWebinarDetailsAction.complete.toString(): {
      const stateCopy = updateEmailSubjects(state, action.details);
      return stateCopy;
    }
    case getCustomEmailDisclaimerAction.toString(): {
      return {
        ...state,
        maliciousError: false
      };
    }
    case getCustomEmailDisclaimerAction.complete.toString(): {
      const data = action.data;
      if (!data.emailDisclaimerEnabled && !data.emailDisclaimerText) {
        data.emailDisclaimerText = t('cards.webinar.details.email.details.emailSection.customEmailDisclaimer.defaultEmailText');
      }
      return {
        ...state,
        customEmailDisclaimer: { ...data }
      };
    }
    case getCustomEmailDisclaimerAction.failed.toString():
    case updateEmailDisclaimerAction.failed.toString(): {
      return {
        ...state,
        error: action.error,
        maliciousError: action.maliciousError
      };
    }
    case updateEmailDisclaimerAction.complete.toString(): {
      const data = action.data;
      return {
        ...state,
        customEmailDisclaimer: { ...data }
      };
    }
    case getCertificateInfoAction.complete.toString(): {
      const data = action.data;
      const certificateInfo = { ...state.certificateInfo };
      certificateInfo.obj = { ...data };
      return {
        ...state,
        certificateInfo
      };
    }
    case updateCertificateInfoAction.toString():
    case deleteCertificateInfoAction.toString(): {
      const certificateInfo = { ...state.certificateInfo };
      certificateInfo.isFetching = true;
      return {
        ...state,
        certificateInfo
      };
    }
    case updateCertificateInfoAction.complete.toString():
    case updateCertificateInfoAction.failed.toString():
    case deleteCertificateInfoAction.failed.toString(): {
      const certificateInfo = { ...state.certificateInfo };
      certificateInfo.isFetching = false;
      return {
        ...state,
        certificateInfo
      };
    }
    case deleteCertificateInfoAction.complete.toString(): {
      const certificateInfo = { ...state.certificateInfo };
      certificateInfo.obj = {};
      certificateInfo.isFetching = false;
      return {
        ...state,
        certificateInfo
      };
    }
    case getCertificateUrlAction.toString(): {
      const certificateInfo = { ...state.certificateInfo };
      certificateInfo.fetchingCertificateUrl = true;
      return {
        ...state,
        certificateInfo
      };
    }
    case getCertificateUrlAction.complete.toString(): {
      const certificateInfo = { ...state.certificateInfo };
      certificateInfo.fetchingCertificateUrl = false;
      return {
        ...state,
        certificateInfo
      };
    }
    case getCertificateUrlAction.failed.toString(): {
      const certificateInfo = { ...state.certificateInfo };
      certificateInfo.fetchingCertificateUrl = false;
      return {
        ...state,
        certificateInfo
      };
    }
    default:
      return state;
  }
};
